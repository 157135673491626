import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  i : number;

  imgs : String[] = [
    "apricot",
    "apple-tree",
    "grapes",
    "plum",
    "red-berries"
  ];

  constructor() { }

  ngOnInit() {
    this.i = this.getRandomIndex(this.imgs.length);
  }

  getRandomImg(){
    let img = this.imgs[this.i];
    return `assets/photo/banner/${img}.jpg`
  }

  getRandomIndex(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }

}
