import { Component, OnInit } from '@angular/core';

interface PortfolioCard {
  imgSource: string;
  preTranslateName: string;
  preTranslateDescription: string;
}

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  portfolioCards: PortfolioCard[] = [
    {imgSource: 'assets/photo/portfolio/mowing.svg', preTranslateName: 'MOWING', preTranslateDescription: 'MOWING-DESCRIPTION'},
    {imgSource: 'assets/photo/portfolio/pruning.svg', preTranslateName: 'PRUNING', preTranslateDescription: 'PRUNING-DESCRIPTION'},
    {imgSource: 'assets/photo/portfolio/tree-cutting.svg', preTranslateName: 'TREE-CUTTING', preTranslateDescription: 'TREE-CUTTING-DESCRIPTION'},
    {imgSource: 'assets/photo/portfolio/pesticide.svg', preTranslateName: 'PESTICIDE', preTranslateDescription: 'PESTICIDE-DESCRIPTION'},
    {imgSource: 'assets/photo/portfolio/tractor.svg', preTranslateName: 'TRACTOR', preTranslateDescription: 'TRACTOR-DESCRIPTION'},
    {imgSource: 'assets/photo/portfolio/fertilizer.svg', preTranslateName: 'FERTILIZER', preTranslateDescription: 'FERTILIZER-DESCRIPTION'}
  ]

}
