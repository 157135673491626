import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSidenavContainer } from '@angular/material';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {
  @ViewChild(MatSidenavContainer, {static: false}) sidenavContainer: MatSidenavContainer;
  @ViewChild('dropdown', {read: ElementRef, static: false}) dropdown: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  closeAndDropFocus(){
    this.sidenavContainer.close();
    this.dropdown.nativeElement.blur();
  }

}
