import { Component } from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    translate: TranslateService,
    titleService: Title) {
    //bővíteni, ha lesz nyelv
    translate.addLangs(['hu']);
    translate.setDefaultLang('hu');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/hu|en/) ? browserLang : 'hu');

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // Change page title when user changes language preference
      translate.get('COMPANY-NAME').subscribe((res: string) => {
        titleService.setTitle(res);
      });
    });
  }
}
