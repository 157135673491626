import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

interface Reference{
  baseImgRoute: string;
  preTranslateName: string;

  innerPreTranslateDescription: string;
  innerImgSources: string[];
}

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  references: Reference[] = [
    {baseImgRoute: 'assets/photo/references/danube-weekend-house/', preTranslateName: 'REFERENCE-DANUBE-WEEKEND-HOUSE', innerPreTranslateDescription: 'REFERENCE-DANUBE-WEEKEND-HOUSE-DESCRIPTION', innerImgSources: ['1', '2', '3']},
  ]

  openDialog(reference: Reference): void {
    const dialogRef = this.dialog.open(ReferenceDialog, {
      width: '75vw',
      maxHeight: '90vh',
      data: reference,
      panelClass: 'custom-modalbox'
    });
  }

}

@Component({
  selector: 'reference-dialog',
  templateUrl: './reference-dialog.html',
  styleUrls: ['./references.component.css']
})
export class ReferenceDialog {

  constructor(
    public dialogRef: MatDialogRef<ReferenceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Reference) {}

    
  ngAfterViewInit() {
    const swipers = document.querySelectorAll('.swiper-container');   
    swipers.forEach((swiper) => {
        swiper['swiper'].loopCreate();  
     })
  }
}
